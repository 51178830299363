























import { Component, Mixins, Prop } from 'vue-property-decorator'
import ComponentHelperBase from '../../mixins/ComponentHelperBase'
import { AddressInfo } from '../../api'

@Component({

})
export default class AddressSelectElement extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) address!: AddressInfo

  @Prop({ default: 0 }) isDefault!: number
}
