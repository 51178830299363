import { render, staticRenderFns } from "./PriceTiers.vue?vue&type=template&id=417b738e&scoped=true&"
import script from "./PriceTiers.vue?vue&type=script&lang=ts&"
export * from "./PriceTiers.vue?vue&type=script&lang=ts&"
import style0 from "./PriceTiers.vue?vue&type=style&index=0&id=417b738e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417b738e",
  null
  
)

export default component.exports