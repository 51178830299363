



























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AddressInfo, Api, CountryInfo, SchoolModel } from '../../api'
import ComponentHelperBase from '../../mixins/ComponentHelperBase'
import AddressModal from './AddressModal.vue'
import AddressSelectElement from './AddressSelectElement.vue'

@Component({
  components: {
    AddressModal,
    AddressSelectElement
  }
})
export default class AddressSelect extends Mixins(ComponentHelperBase) {
  @Prop({ default: undefined }) addresses!: AddressInfo[] | undefined

  @Prop({ default: null }) address!: AddressInfo | null

  @Prop({ default: 0 }) defaultAddressId!: number

  @Prop({ default: false }) allowManual!: boolean

  @Prop({ default: undefined }) title: string | undefined

  @Prop({ default: undefined }) countries!: CountryInfo[] | undefined

  @Prop({ default: null }) school!: SchoolModel | null

  @Prop({ default: false }) forceCountryMatch! :boolean

  @Prop({ required: true }) cloneDeep!: <T>(value: T) => T

  @Prop({ default: true }) selectFirst!: boolean

  private selectedAddressId: number | null = null
  private countriesData: CountryInfo[] = []
  private addressesData: AddressInfo[] = []
  private timesExpanded = 0

  public manualEntry: boolean = false
  public addressToEdit: AddressInfo | null = null
  public query: string = ''

  get selectedAddress () {
    return this.addressesData.find(a => a.id === this.selectedAddressId) ?? (this.address?.id === this.selectedAddressId ? this.address : null)
  }

  get visibleAddresses () {
    const addresses: AddressInfo[] = []

    if (this.selectedAddress) {
      addresses.push(this.selectedAddress)
    }
    if (this.timesExpanded === 0) {
      return addresses
    }

    const defaultAddress = this.addressesData.find(a => a.id === this.defaultAddressId)

    if (defaultAddress !== undefined && !defaultAddress.is_deleted && addresses.find(a => a.id === defaultAddress.id) === undefined) {
      addresses.push(defaultAddress)
    }

    const targetAmount = (this.timesExpanded * 3)

    addresses.push(...this.addressesData.filter(a => addresses.find(a2 => a2.id === a.id) === undefined).slice(0, targetAmount - addresses.length))

    return addresses
  }

  async mounted () {
    if (this.countries === undefined) {
      this.countriesData = await Api.getCountries()
    }

    if (this.addresses === undefined) {
      this.addressesData = (await Api.getMySchoolAddresses({ take: 1000 })).items
    }
  }

  @Watch('countries', { immediate: true })
  countriesChanged (val: CountryInfo[] | undefined) {
    this.countriesData = val ?? []
  }

  @Watch('addresses', { immediate: true })
  addressesChanged (val: AddressInfo[] | undefined) {
    this.addressesData = val ?? []
  }

  @Watch('addressesData', { immediate: true })
  addressesDataChanged (val: AddressInfo[]) {
    if (!this.selectedAddressId && !this.address && this.selectFirst) {
      this.selectedAddressId = val[0]?.id ?? null

      if (this.selectedAddressId !== null) {
        this.timesExpanded = 0
      }
    }
  }

  @Watch('address.id', { immediate: true })
  changeAddress () {
    this.selectedAddressId = this.address?.id ?? null

    if (this.selectedAddressId !== null) {
      this.timesExpanded = 0
    }
  }

  @Watch('selectedAddress.id', { immediate: true })
  changedSelectedAddress (val: number | undefined, oldVal: number | undefined) {
    if (val !== undefined) {
      this.timesExpanded = 0
    }

    if (oldVal !== undefined || val !== this.address?.id) {
      this.$emit('changedSelectedAddress', this.selectedAddress)
    }
  }

  addressCreated (address: AddressInfo) {
    this.addressesData.push(address)
    this.selectedAddressId = address.id
    this.timesExpanded = 0

    this.$emit('input', address)
    this.$emit('address-created', address)
  }

  addressUpdated (id: number, address: AddressInfo) {
    this.addressesData = this.addressesData.map(a => a.id === id ? address : a)
    this.selectedAddressId = id
    this.timesExpanded = 0

    this.$emit('input', address)
    this.$emit('changedSelectedAddress', address)
    this.$emit('address-updated', address)
  }

  protected async loadCountries () {
    try {
      this.countriesData = await Api.getCountries()
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Error retrieving country information',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }
}
