















































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AddressInfo, AddressToAdd, Api, CountryCode, CountryInfo, CountryZoneInfo } from '../../api'
import ComponentHelperBase from '../../mixins/ComponentHelperBase'

@Component({
})
export default class AddAddress extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) countries!: CountryInfo[]

  @Prop({ default: null }) forceCountry!: CountryCode | null

  @Prop({ default: null }) address!: AddressInfo | null

  @Prop({ required: true }) cloneDeep!: <T>(value: T) => T

  @Prop({ required: true }) defaultCountry!: CountryCode

  @Prop({ required: true }) schoolId!: number

  @Watch('address', { immediate: true })
  onAddressChange (val: AddressInfo | null) {
    if (val !== null) {
      this.localAddress = this.cloneDeep(val)
    }
  }

  @Watch('localAddress.country_code', { immediate: true })
  countryChanged (val: string) {
    const country = this.countries.find(c => c.code === val)
    this.countrySearch = country?.name ?? ''
  }

  public localAddress: AddressToAdd = {
    name: '',
    email: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    town: '',
    county: '',
    country: '',
    postcode: '',
    country_code: 'GB-ENG',
    school_id: this.schoolId,
    lat: null,
    long: null,
    zone_code: null
  }

  loading = false

  zones: CountryZoneInfo[] = []

  countrySearch: string = ''

  getDisabled () {
    if (this.localAddress.address_1.length === 0) {
      return true
    }

    if (this.localAddress.postcode.length === 0) {
      return true
    }

    if (this.localAddress.country_code.length === 0) {
      return true
    }

    if (this.isCountyRequired && this.localAddress.county.length === 0) {
      return true
    }

    return false
  }

  countrySearchBlurred () {
    this.$nextTick(() => {
      const selectedCountry = this.countries.find(c => c.code === this.localAddress.country_code)
      const defaultCountry = this.countries.find(c => c.code === this.defaultCountry)
      const searchedCountry = this.countries.find(c => c.name.toLowerCase() === this.countrySearch.toLowerCase())

      if (searchedCountry) {
        this.countrySearch = searchedCountry.name
        this.localAddress.country_code = searchedCountry.code
        this.localAddress.country = searchedCountry.name
      } else if (this.filteredCountries.length && this.countrySearch.length > 1) {
        this.countrySearch = this.filteredCountries[0].name
        this.localAddress.country_code = this.filteredCountries[0].code
        this.localAddress.country = this.filteredCountries[0].name
      } else if (selectedCountry) {
        this.countrySearch = selectedCountry.name
      } else if (defaultCountry) {
        this.localAddress.country_code = this.defaultCountry
        this.localAddress.country = defaultCountry.name
        this.countrySearch = defaultCountry.name
      }
    })
  }

  async mounted () {
    this.zones = await Api.getCountriesZones(null)
    this.autoSetCountryCode(this.forceCountry ?? this.defaultCountry ?? 'GB-ENG')
  }

  async submitAddress () {
    try {
      this.loading = true

      if (
        this.localAddress.name.length === 0 ||
        this.localAddress.email.length === 0 ||
        this.localAddress.town.length === 0 ||
        this.localAddress.address_1.length === 0 ||
      this.localAddress.postcode.length === 0 ||
      this.localAddress.country_code.length === 0 ||
      (this.isCountyRequired && this.localAddress.county.length === 0)
      ) {
        return this.$buefy.toast.open({ message: 'Missing one or more required fields.', position: 'is-bottom', type: 'is-danger' })
      }

      if (this.address) {
        const editedAddress = await Api.editAddress(this.address.id, this.localAddress, null)
        this.$emit('address-updated', this.address.id, editedAddress)
      } else {
        const newAddress = await Api.createAddress(this.localAddress, null)
        this.$emit('address-created', newAddress)
      }

      this.$buefy.toast.open({
        message: `Address ${this.address ? 'updated' : 'created'}`,
        position: 'is-bottom',
        type: 'is-success'
      })

      this.close()
    } catch (err) {
      this.$buefy.toast.open({
        message: `Could not ${this.address ? 'edit' : 'add'} address`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  @Watch('defaultCountry', { immediate: true })
  defaultCountryChanged (val: CountryCode) {
    if (val !== null) {
      this.autoSetCountryCode(val)
    }
  }

  get filteredCountries () {
    if (this.countrySearch.length < 1) {
      return this.countries
    }

    return this.countries.filter(c => c.name.toLowerCase().includes(this.countrySearch.toLowerCase()))
  }

  get zonesForCountry (): CountryZoneInfo[] {
    if (!this.localAddress.country_code || this.countries.length === 0 || this.zones.length === 0) {
      return []
    }

    const countryDetails = this.countries.find(c => c.code === this.localAddress.country_code)

    if (!countryDetails) {
      throw new Error('Could not find country')
    }

    return this.zones.filter(z => z.country_id === countryDetails.id)
  }

  get isCountyRequired () {
    return this.zonesForCountry.length > 0
  }

  get isCountyValid () {
    return !this.isCountyRequired || this.zonesForCountry.find(z => this.localAddress.county === z.name || this.localAddress.county === z.code) !== undefined
  }

  private autoSetCountryCode (val: CountryCode) {
    if (this.forceCountry) { this.localAddress.country_code = this.forceCountry; return } // respect the forced country
    if (this.address && this.address.country_code) { this.localAddress.country_code = this.address.country_code; return } // If editing an address, use that address and don't overwrite it
    if (val) { this.localAddress.country_code = val; return }
    this.localAddress.country_code = 'GB-ENG'
  }

  close () {
    this.$emit('close')
  }
}
